
import * as ipxRuntime$8Iyq86fcfO from '/app/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.2.1_@libsql+client@0.14.0_better-sqlite3@11.8.1__ioredis@5.4.2_magicast@0.3.5_rollup@4.32.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as ipxRuntime$glkc5F3klM from '/app/image-provider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$8Iyq86fcfO, defaults: {"maxAge":31536000} },
  ['ipx']: { provider: ipxRuntime$glkc5F3klM, defaults: undefined }
}
        